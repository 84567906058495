import React from "react"
import Layout from "../components/layout/layout"
import Header from "../components/header"
import MobileNav from "../components/mobileNav"

export default function Blog() {
  return (
    <>
      <Header />
      <MobileNav />
      <Layout></Layout>
    </>
  )
}
